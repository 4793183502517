import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper';
import PlayBtn from '../images/PlayBtn.svg';

const videosSwiper = [
  {
    src: 'https://motomtech-landing-page-assets.s3.amazonaws.com/rayan_AdobeExpress.mp4',
    name: 'Ryan Allenbach',
    company: 'CEO & Founder Agreed',
    linkedin: 'https://www.linkedin.com/in/ryan-allenbach/',
  },

  {
    src: 'https://motomtech-landing-page-assets.s3.amazonaws.com/test1_AdobeExpress.mp4',
    name: 'Ren Rice',
    company: 'CEO Paramount Acceptance',
    linkedin: 'https://www.linkedin.com/in/ren-rice-a64608191/',
  },
  {
    src: 'https://motomtech-landing-page-assets.s3.amazonaws.com/test2_AdobeExpress.mp4',
    name: 'Carol Ng',
    company: 'CEO SmartCaps',
    linkedin: 'https://www.linkedin.com/in/carol-n-116a15a/',
  },
  {
    src: 'https://motomtech-landing-page-assets.s3.amazonaws.com/Test4_AdobeExpress.mp4',
    name: 'Clifford Watkin',
    company: 'CEO Circle Strategies',
    linkedin: 'https://www.linkedin.com/in/cliffordwatkin/',
  },
  {
    src: 'https://motomtech-landing-page-assets.s3.amazonaws.com/Testimonial_3_AdobeExpress.mp4',
    name: 'Ryan Harris',
    company: 'Co-Founder & COO at Alysio',
    linkedin: 'https://www.linkedin.com/in/ryan-p-harris/',
  },
  {
    src: 'https://motomtech-landing-page-assets.s3.amazonaws.com/brad_moss.mp4',
    name: 'Brad Moss',
    company: 'CEO EnhancedAI',
    linkedin: 'https://www.linkedin.com/in/bradmoss/',
  },
  {
    src: 'https://motomtech-landing-page-assets.s3.amazonaws.com/Beemer.mp4',
    name: 'Ben Warren',
    company: 'Co-owner of Valor',
    linkedin: 'https://www.linkedin.com/in/ben-warren-a2446715/',
  },
];
const AboutUsTeamSwiper = ({ color }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  //create ref for each video array
  const ref = useRef([]);
  ref.current = videosSwiper.map((_, i) => ref.current[i] || React.createRef());

  const onSlideChange = swiper => {
    ref.current[activeIndex].current.pause();
    setActiveIndex(swiper.activeIndex);
    //  index stop video
  };

  //check if video is playing
  const checkVideo = useCallback(() => {
    if (ref.current[activeIndex].current) {
      if (ref.current[activeIndex].current.paused) {
        setIsVideoPlaying(false);
      } else {
        setIsVideoPlaying(true);
      }
    }
  }, [activeIndex]);

  useEffect(() => {
    //check if video is playing
    checkVideo();
  }, [checkVideo]);

  return (
    <div className='aboutus-swiper-section full-container'>
      <div className='full-container'>
        <div className='aboutus-swiper-section-avatars'>
          <div
            className={`aboutus-swiper-section-avatars-box ${
              !color ? 'aboutus-swiper-section-avatars-box-black' : ''
            }`}
          >
            <Swiper
              onSlideChange={onSlideChange}
              ref={swiperRef}
              className='aboutus-swiper-section-avatars-box-swipper'
              modules={[EffectCoverflow, Navigation]}
              effect='coverflow'
              navigation={true}
              grabCursor={false}
              centeredSlides={true}
              slidesPerView={1}
              coverflowEffect={{
                rotate: 0,
                stretch: 400,
                depth: 200,
                modifier: 1,
                slideShadows: false,
              }}
              breakpoints={{
                500: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
              }}
            >
              {videosSwiper.map((video, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div
                      className='aboutus-swiper-section-avatars-box-swipper-wrapper'
                      style={{
                        height: '483px',
                        maxWidth: '270px',
                        objectFit: 'cover',
                      }}
                    >
                      {i === activeIndex && !isVideoPlaying && (
                        <img
                          src={PlayBtn}
                          style={{
                            cursor: 'pointer',
                            zIndex: '100',
                          }}
                          onClick={() => {
                            ref.current[activeIndex].current.play();
                            setIsVideoPlaying(true);
                          }}
                          alt={'image'}
                        />
                      )}
                      <video
                        controls={i === activeIndex && isVideoPlaying}
                        ref={ref.current[i]}
                        style={{
                          height: '483px',
                          maxWidth: '270px',
                          borderRadius: '20px',
                          objectFit: 'cover',
                        }}
                      >
                        <source src={video.src} />
                      </video>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div>
              <div className='aboutus-swiper-section-avatars-box-swipper-text text-center mt-3'>
                <a
                  href={videosSwiper[activeIndex].linkedin}
                  target='_blank'
                  style={{
                    fontSize: '24px',
                    color: color ? color : '',
                    textDecoration: '',
                  }}
                >
                  {videosSwiper[activeIndex].name}
                </a>
                <p
                  style={{
                    fontSize: '14px',
                    color: color ? color : '',
                  }}
                >
                  {videosSwiper[activeIndex].company}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id='client-stories' />
    </div>
  );
};

export default AboutUsTeamSwiper;
