import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Navigation } from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { graphql, useStaticQuery } from 'gatsby';

// This file is used at : [ home.js ]

SwiperCore.use([Autoplay]);
const WebsLogoSwiper = (props) => {
  const {
    wpPage: {
      homePageProjectsSection: {
        homePageProjectsAlysioImage: {
          localFile: {
            childImageSharp: { gatsbyImageData: rakutenLogo },
          },
        },
        homePageProjectsPoroImage: {
          localFile: {
            childImageSharp: { gatsbyImageData: spaceiqLogo },
          },
        },
        homePageProjectsMccleeryImage: {
          localFile: {
            childImageSharp: { gatsbyImageData: paramountLogo },
          },
        },
        homePageProjectAgreedImage: {
          localFile: {
            childImageSharp: { gatsbyImageData: firstmileLogo },
          },
        },
        homePageProjectPurposeImage: {
          localFile: {
            childImageSharp: { gatsbyImageData: epturaLogo },
          },
        },
        homePageProjectsOneclickappImage: {
          localFile: {
            childImageSharp: { gatsbyImageData: bashLogo },
          },
        },
        digitalApplicationSwiperImage: {
          localFile: {
            childImageSharp: { gatsbyImageData: archibusLogo },
          },
        },
        homePageProjectsK2Image: {
          localFile: {
            childImageSharp: { gatsbyImageData: k2Logo },
          },
        },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        wpPage(slug: { eq: "home" }) {
          homePageProjectsSection {
            homePageProjectsAlysioImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            homePageProjectsPoroImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            homePageProjectsMccleeryImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            homePageProjectAgreedImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            homePageProjectPurposeImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }

            homePageProjectsOneclickappImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            digitalApplicationSwiperImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            homePageProjectsK2Image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <div className='development-section'>
      <Swiper
        navigation={false}
        modules={[Navigation]}
        className='mySwiper'
        spaceBetween={50}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop
        slidesPerView={3}
        breakpoints={{
          768: {
            slidesPerView: 3,
          },

          1320: {
            slidesPerView: 6,
          },
        }}
      >
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={rakutenLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={spaceiqLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={paramountLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={firstmileLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={epturaLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={bashLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={k2Logo}
              className='development-section-wrapper-img'
              style={{ maxWidth: '80px' }}
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={archibusLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={rakutenLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={spaceiqLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={paramountLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={firstmileLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={epturaLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={bashLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={k2Logo}
              className='development-section-wrapper-img'
              style={{ maxWidth: '80px' }}
              alt='logo'
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='development-section-wrapper'>
            <GatsbyImage
              image={archibusLogo}
              className='development-section-wrapper-img'
              alt='logo'
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
export default WebsLogoSwiper;
