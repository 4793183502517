import React from 'react';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

// This file is used at : [ home.js ]

const TransformYourBuisnessComponent = props => {
  const {
    swiperTitle,
    swiperSubtitle,
    bannerTitle,
    agreedImage,
    festoImage,
    hyraImage,
    loginhoodImage,
    poroImage,
    purposeImage,
    smartcapsImage,
    wedaxImage,
  } = props;
  const Images = [
    agreedImage,
    festoImage,
    hyraImage,
    loginhoodImage,
    poroImage,
    purposeImage,
    smartcapsImage,
    wedaxImage,
  ];
  return (
    <div className='transform-your-bisness-section'>
      <div className='transform-your-bisness-section-head'>
        <h1
          className='transform-your-bisness-section-head-title'
          dangerouslySetInnerHTML={{ __html: swiperTitle }}
        />
        <p
          className='transform-your-bisness-section-head-text'
          dangerouslySetInnerHTML={{ __html: swiperSubtitle }}
        />
      </div>
      <div className='transform-your-bisness-section-swiper'>
        <Swiper
          pagination={{
            type: 'progressbar',
          }}
          navigation={false}
          modules={[Pagination, Navigation]}
          className='transform-your-bisness-section-swiper-section'
          spaceBetween={50}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop
          slidesPerView={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 3,
            },
          }}
        >
          {Images.map((img, i) => (
            <SwiperSlide key={i}>
              <div className='transform-your-bisness-section-swiper-section-wrapper'>
                <GatsbyImage
                  className='transform-your-bisness-section-swiper-section-wrapper-img'
                  image={img}
                  alt={`logo-${i}`}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className='transform-your-bisness-section-card'>
        <h1
          className='card-title'
          dangerouslySetInnerHTML={{ __html: bannerTitle }}
        />
        <div className='card-button-row'>
          <Link
            to='https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1DvKx-W5bQIQqYRPigG4-BJhmnc3tqIcrtPt8q4Tg_UCGIc7Y65IUVAloWN8BlJkbqrmDwb4r2'
            target='_blank'
            className='card-button'
          >
            Book A Call
          </Link>
        </div>
      </div>
    </div>
  );
};
export default TransformYourBuisnessComponent;
