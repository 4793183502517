import React, { useEffect, useRef } from 'react';

// This file is used at : [ homeComponent.js ]

const MotomtechVideo = ({ isOpen, onClose }) => {
  const handleModalClose = () => {
    onClose();
  };

  const videoRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden ';

      //set video in fullscreen
      videoRef.current.requestFullscreen();
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isOpen]);

  const CloseIcon = () => (
    <svg
      viewBox='0 0 38 39'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='home-section-modal-message-btn-close'
    >
      <path
        d='M18.9992 15.1442L33.4367 0.113647L37.5608 4.40723L23.1233 19.4378L37.5608 34.4684L33.4367 38.762L18.9992 23.7314L4.56167 38.762L0.4375 34.4684L14.875 19.4378L0.4375 4.40723L4.56167 0.113647L18.9992 15.1442Z'
        fill='white'
      />
    </svg>
  );

  return (
    <>
      {isOpen && (
        <>
          <div className={`full-screen-modal ${isOpen ? 'is-open' : ''}`}>
            <div className='full-screen-modal-overlay'>
              <div className='home-section-modal-message'>
                <button
                  onClick={handleModalClose}
                  className='home-section-modal-message-btn'
                >
                  <CloseIcon style={{ color: 'white' }} />
                </button>

                <div className='home-section-modal-message-video'>
                  <video controls autoPlay={isOpen} ref={videoRef}>
                    <source src='https://assets.motomtech.com/Final_Motomtech_AdobeExpress.mp4' />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MotomtechVideo;
