import { Link } from 'gatsby';
import React from 'react';
import Swiper from './swiper';
import { GatsbyImage } from 'gatsby-plugin-image';

// This file is used at : [ home.js ]

const SolutionsHomeComponent = props => {
  const {
    solutionsTitle,
    solutionsDescription,
    solutionsProductTitle,
    solutionsProductDescription,
    solutionsWebApplicaationTitle,
    solutionsWebApplicaationDescription,
    solutionsMobileApplicationtTitle,
    solutionsMobileApplicationtDescription,
    solutionsSalesforceTitle,
    solutionsSalesforceDescription,
    buisnessIdeaImage,
    scaleBusinessImage,
    optimizeBusinessImage,
  } = props;

  return (
    <>
      <div className='digital-solution-section'>
        <div className='digital-solution'>
          <div className='digital-solution-head'>
            <h1
              className='digital-solution-head-title'
              dangerouslySetInnerHTML={{ __html: solutionsTitle }}
            />
            <p
              className='digital-solution-head-subtitle'
              dangerouslySetInnerHTML={{ __html: solutionsDescription }}
            />
          </div>
          <div className='digital-solution-body'>
            <div className='digital-solution-body-content'>
              <div className='digital-solution-body-image'>
                <GatsbyImage
                  className='image-shadow'
                  image={buisnessIdeaImage}
                  alt='business-idea-image'
                />
              </div>
              <div className='digital-solution-body-description'>
                <h1
                  className='digital-solution-body-description-title'
                  dangerouslySetInnerHTML={{ __html: solutionsProductTitle }}
                />
                <p
                  className='digital-solution-body-description-paragraph'
                  dangerouslySetInnerHTML={{
                    __html: solutionsProductDescription,
                  }}
                />
                <Link
                  to='/service-saas-mvp'
                  className='solutionHome-section-button-learn'
                >
                  Learn more
                </Link>
              </div>
            </div>
            <div className='digital-solution-body-content digital-solution-body-content-border'>
              <div className=' digital-solution-body-image'>
                <GatsbyImage
                  className='image-shadow'
                  image={optimizeBusinessImage}
                  alt='optimize business image'
                />
              </div>
              <div className=' digital-solution-body-description'>
                <h1
                  className='digital-solution-body-description-title'
                  dangerouslySetInnerHTML={{
                    __html: solutionsWebApplicaationTitle,
                  }}
                />
                <p
                  className='digital-solution-body-description-paragraph'
                  dangerouslySetInnerHTML={{
                    __html: solutionsWebApplicaationDescription,
                  }}
                />
                <Link
                  to='/service-digitalApplication'
                  className='solutionHome-section-button-learn'
                >
                  Learn more
                </Link>
              </div>
            </div>
            <div className=' digital-solution-body-content'>
              <div className=' digital-solution-body-image'>
                <GatsbyImage
                  className='image-shadow'
                  image={scaleBusinessImage}
                  alt='scale-business-image'
                />
              </div>
              <div className=' digital-solution-body-description'>
                <h1
                  className='digital-solution-body-description-title'
                  dangerouslySetInnerHTML={{
                    __html: solutionsMobileApplicationtTitle,
                  }}
                />
                <p
                  className='digital-solution-body-description-paragraph'
                  dangerouslySetInnerHTML={{
                    __html: solutionsMobileApplicationtDescription,
                  }}
                />
                <Link
                  to='/service-saasTechnical'
                  className='solutionHome-section-button-learn'
                >
                  Learn more
                </Link>
              </div>
              <div className='digital-solution-body-bgImg'></div>
            </div>
          </div>

          <div className='digital-solution-videoSwiper'>
            <div className='digital-solution-videoSwiper-header'>
              <h1
                className='digital-solution-videoSwiper-header-title'
                dangerouslySetInnerHTML={{ __html: solutionsSalesforceTitle }}
              />
              <p
                className='digital-solution-videoSwiper-header-text'
                dangerouslySetInnerHTML={{
                  __html: solutionsSalesforceDescription,
                }}
              />
            </div>
          </div>
          <Swiper color={'#fff'} />
        </div>
      </div>
    </>
  );
};
export default SolutionsHomeComponent;
