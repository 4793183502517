import React from 'react';
// import { Pagination, Navigation } from "swiper";
import SwiperCore, { Autoplay } from 'swiper';
// import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';

// This file is used at : [ home.js ]

SwiperCore.use([Autoplay]);
const SimpleScaleSucceedComponent = props => {
  const {
    projectTitle,
    projectAgreedTitle,
    projectBashTitle,
    projectBashDescription,
    projectNaxxaTitle,
    projectNaxxaDescription,
    projectWedaxTitle,
    projectWedaxDescription,
  } = props;

  const simpleScaleSucceedContent = [
    {
      title: projectBashTitle,
      text: projectBashDescription,
    },
    {
      title: projectNaxxaTitle,
      text: projectNaxxaDescription,
    },
    {
      title: projectWedaxTitle,
      text: projectWedaxDescription,
    },
  ];

  const SimpleScaleSucceed = ({ data }) => {
    return (
      <div className='simple-scalable-yours'>
        <h1
          className='simple-scalable-yours-title'
          dangerouslySetInnerHTML={{ __html: data.title }}
        />
        <p
          className='simple-scalable-yours-text'
          dangerouslySetInnerHTML={{ __html: data.text }}
        />
      </div>
    );
  };

  return (
    <div className='simply-scale-succed-compoent'>
      <div className='simply-sacale-succeed-compnent-card'>
        <h1
          className='card-title'
          dangerouslySetInnerHTML={{ __html: projectTitle }}
        />
        <p
          className='card-text'
          dangerouslySetInnerHTML={{ __html: projectAgreedTitle }}
        />
        <div className='card-button'>
          <Link
            to='https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ1DvKx-W5bQIQqYRPigG4-BJhmnc3tqIcrtPt8q4Tg_UCGIc7Y65IUVAloWN8BlJkbqrmDwb4r2'
            target='_blank'
            className='card-button-book'
          >
            Book A Call
          </Link>
        </div>
      </div>
      <div className='simply-scale-succed-component-group'>
        {simpleScaleSucceedContent.map((item, i) => {
          return <SimpleScaleSucceed data={item} key={i} />;
        })}
      </div>
    </div>
  );
};
export default SimpleScaleSucceedComponent;
