import React from "react";
import { graphql } from "gatsby";

import WebsLogoSwiper from "../component/websLogoSwiper";
import FooterComponent from "../component/footerComponent";
import HomeComponent from "../component/homeComponent";
import Layout from "../component/layout";
import SimpleScaleSucceedComponent from "../component/simplyScaleSucceedComponent";
import TransformYourBuisnessComponent from "../component/transformYourBuisnessComponent";
import SolutionsHomeComponent from "../component/solutionsHomeComponent";

const Home = (props) => {
  const {
    data: {
      wpPage: {
        seo,
        homePageFullCycleSection: {
          homePageFullCycleQualityDescription,
          homePageFullCycleQualityTitle,
          homePageFullCycleTitle,
          homePageTransformBusinessAgreedImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: agreedImage },
            },
          },
          homePageTransformBusinessFestoImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: festoImage },
            },
          },
          homePageTransformBusinessHyraImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: hyraImage },
            },
          },
          homePageTransformBusinessLoginhoodImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: loginhoodImage },
            },
          },
          homePageTransformBusinessPoroImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: poroImage },
            },
          },
          homePageTransformBusinessPurposeImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: purposeImage },
            },
          },
          homePageTransformBusinessSmartcapsImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: smartcapsImage },
            },
          },
          homePageTransformBusinessWedaxImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: wedaxImage },
            },
          },
        },
        homePageHeaderSection: {
          homeHeaderBookButton,
          homeHeaderDescription,
          homeHeaderLearnButton,
          homeHeaderTitle,
        },
        homePageProjectsSection: {
          homePageProjectsAgreedTitle,
          homePageProjectsBashDescription,
          homePageProjectsBashTitle,
          homePageProjectsNaxxaDescription,
          homePageProjectsNaxxaTile,
          homePageProjectsTitle,
          homePageProjectsWedaxDescription,
          homePageProjectsWedaxTitle,
        },
        homePageServicesSection: {
          homePageServicesDescription,
          homePageServicesMobileDescription,
          homePageServicesMobileSalesforceDescription,
          homePageServicesMobileTitle,
          homePageServicesMobileSalesforceTitle,
          homePageServicesProductDescription,
          homePageServicesProductTitle,
          homePageServicesTitle,
          homePageServicesWebDescription,
          homePageServicesWebTitle,
          homePageServicesImage1: {
            localFile: {
              childImageSharp: { gatsbyImageData: buisnessIdeaImage },
            },
          },
          homePageServicesImage2: {
            localFile: {
              childImageSharp: { gatsbyImageData: scaleBusinessImage },
            },
          },
          homePageServicesImage3: {
            localFile: {
              childImageSharp: { gatsbyImageData: optimizeBusinessImage },
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout
      title={title}
      seo={{
        title: "Digital Solutions Company | Website & Web App Consulting",
        metaDesc:
          "Motomtech delivers innovative IT solutions tailored to your needs. Explore our services for IT augmentation, outsourcing, development,and digital applications.\n",
      }}
    >
      <div className="home-screen">
        <HomeComponent
          homeTitle={homeHeaderTitle}
          homeDescription={homeHeaderDescription}
          homeButtonBook={homeHeaderBookButton}
          homeButtonMessage={"Send a message"}
          homeButtonIntroduction={"Introduction"}
          isHomePage={true}
        />
        <WebsLogoSwiper />
        <SolutionsHomeComponent
          isHome="true"
          solutionsTitle={homePageServicesTitle}
          solutionsDescription={homePageServicesDescription}
          solutionsProductTitle={homePageServicesProductTitle}
          solutionsProductDescription={homePageServicesProductDescription}
          solutionsLink={homeHeaderLearnButton}
          solutionsWebApplicaationTitle={homePageServicesWebTitle}
          solutionsWebApplicaationDescription={homePageServicesWebDescription}
          solutionsMobileApplicationtTitle={homePageServicesMobileTitle}
          solutionsMobileApplicationtDescription={
            homePageServicesMobileDescription
          }
          solutionsSalesforceTitle={homePageServicesMobileSalesforceTitle}
          solutionsSalesforceDescription={
            homePageServicesMobileSalesforceDescription
          }
          solutionsDescriptionLink={homeHeaderLearnButton}
          buisnessIdeaImage={buisnessIdeaImage}
          scaleBusinessImage={scaleBusinessImage}
          optimizeBusinessImage={optimizeBusinessImage}
        />

        <SimpleScaleSucceedComponent
          projectTitle={homePageProjectsTitle}
          projectAgreedTitle={homePageProjectsAgreedTitle}
          projectBashTitle={homePageProjectsBashTitle}
          projectBashDescription={homePageProjectsBashDescription}
          projectNaxxaTitle={homePageProjectsNaxxaTile}
          projectNaxxaDescription={homePageProjectsNaxxaDescription}
          projectWedaxTitle={homePageProjectsWedaxTitle}
          projectWedaxDescription={homePageProjectsWedaxDescription}
        />
        <TransformYourBuisnessComponent
          swiperTitle={homePageFullCycleTitle}
          swiperSubtitle={homePageFullCycleQualityTitle}
          bannerTitle={homePageFullCycleQualityDescription}
          agreedImage={agreedImage}
          festoImage={festoImage}
          hyraImage={hyraImage}
          loginhoodImage={loginhoodImage}
          poroImage={poroImage}
          purposeImage={purposeImage}
          smartcapsImage={smartcapsImage}
          wedaxImage={wedaxImage}
        />

        <FooterComponent />
      </div>
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "home" }) {
      seo {
        metaDesc
        title
      }
      homePageFullCycleSection {
        homePageFullCycleQualityDescription
        homePageFullCycleQualityTitle
        homePageFullCycleTitle
        homePageTransformBusinessAgreedImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageTransformBusinessFestoImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageTransformBusinessHyraImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageTransformBusinessLoginhoodImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageTransformBusinessPoroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageTransformBusinessPurposeImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageTransformBusinessSmartcapsImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageTransformBusinessWedaxImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      homePageHeaderSection {
        homeHeaderBookButton
        homeHeaderDescription
        homeHeaderLearnButton
        homeHeaderTitle
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      homePageProjectsSection {
        homePageProjectsAgreedDescription
        homePageProjectsAgreedTitle
        homePageProjectsBashDescription
        homePageProjectsBashTitle
        homePageProjectsHyraDescription
        homePageProjectsHyraTitle
        homePageProjectsNaxxaDescription
        homePageProjectsNaxxaTile
        homePageProjectsTitle
        homePageProjectsWedaxDescription
        homePageProjectsWedaxTitle
      }
      homePageServicesSection {
        homePageServicesDescription
        homePageServicesMobileDescription
        homePageServicesMobileSalesforceDescription
        homePageServicesMobileTitle
        homePageServicesMobileSalesforceTitle
        homePageServicesProductDescription
        homePageServicesProductTitle
        homePageServicesTitle
        homePageServicesWebDescription
        homePageServicesWebTitle
        homePageServicesImage1 {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageServicesImage2 {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        homePageServicesImage3 {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default Home;
